.unify-ignore-modal .table-name {
    display: flex;
}

.unify-ignore-modal .table-name .table-name-header {
    font-weight: bold;
    padding: 0 10px;
}

.unify-ignore-modal .header-row {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding-top: 20px;
}

.unify-ignore-modal .header-row .attr-header {
    width: 200px;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    height: 21px;
}

.unify-ignore-modal .header-row .attr-header.score-header {
    width: 100px;
}

.unify-ignore-modal .matches-container {
    height: auto;
    max-height: calc(95vh - 358px);
    overflow-y: auto;
    padding-right: 10px;
}

.unify-ignore-modal .match-row {
    display: flex;
    justify-content: center;
    gap: 25px;
    padding: 10px 0;
    border-bottom: 2px solid var(--greyscale-highlight-color);
}

.unify-ignore-modal .match-row .match-score {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unify-ignore-modal .match-row .attr-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.unify-ignore-modal .match-row .attr-col .attr-name {
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    height: 21px;
}

.unify-ignore-modal .match-row .expand-btn {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.unify-ignore-modal .details-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    padding-top: 10px;
    cursor: pointer;
}

.unify-ignore-modal .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.unify-ignore-modal .slider-container .numb-matches {
    font-weight: bold;
}

.unify-ignore-button-row {
    display: flex;
    justify-content: space-between;
}
