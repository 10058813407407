.tooltip {
    font-size: 13px;
    font-weight: normal;
    width: auto;
}

.tooltip .tooltip-child {
    width: 100%;
    height: 100%;
    display: flex;
}

.tooltip .tooltip-text {
    background-color: var(--font-color);
    color: #fff;
    border-radius: 6px;
    padding: 5px 10px;
    position: fixed;
    visibility: hidden;
}

.tooltip .tooltip-text.bottom-center,
.tooltip .tooltip-text.top-center {
    transform: translateX(-50%);
}

.tooltip .tooltip-text.left-center,
.tooltip .tooltip-text.right-center {
    transform: translateY(-50%);
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    z-index: 99999;
}
